<template>
  <b-badge :variant="getBadgeVariant()">{{ getBadgeLabel() }}</b-badge>
</template>

<script>
export default {
  name: 'SFTPUserStatusBadge',
  props: {
    status: {},
  },
  methods: {
    getBadgeVariant() {
      switch (this.status) {
        case 1:
        case true:
          return 'success';
        case 0:
        case false:
        case undefined:
          return 'danger';
        default:
          console.error(`Unknown value for badge variant: ${this.status}`);
          return 'danger';
      }
    },
    getBadgeLabel() {
      switch (this.status) {
        case 1:
        case true:
          return 'Yes';
        case 0:
        case false:
        case undefined:
          return 'No';
        default:
          console.error(`Unknown value for badge label: ${this.status}`);
          return 'No';
      }
    },
  },
};
</script>
