<template>
  <div>
    <b-table striped hover :items="users" :fields="fields">
      <template #cell(status)="data">
        <UserStatusBadge :status="data.value"/>
      </template>
      <template #cell(actions)="row">
        <b-button
          v-b-tooltip.hover
          title="Show Details"
          variant="primary"
          size="sm"
          :to="'/sftp/users/external/' + row.item.username">
          See Details
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import UserStatusBadge from '@/components/SFTP/Users/UserStatusBadge.vue';

export default {
  name: 'SFTPInternalUsersList',
  components: {
    UserStatusBadge,
  },
  props: {
    users: {
      type: Array,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'username',
          label: 'Username',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'status',
          label: 'Active',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
    };
  },
};
</script>
