<template>
  <div>
    <b-table striped hover :items="users" :fields="fields">
      <template #cell(status)="row">
        <UserStatusBadge :status="row.value"/>
      </template>
      <template #cell(groups)="row">
        <div v-if="row.value">
          <b-badge variant="info" v-for="(entry, idx) in row.value" :key="idx">
            {{entry.name}}
          </b-badge>
        </div>
        <span v-else>-</span>
      </template>
      <template #cell(actions)="row">
        <b-button
          v-b-tooltip.hover
          title="Show Details"
          variant="primary"
          size="sm"
          :to="'/sftp/users/internal/' + row.item.username">
          See Details
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import UserStatusBadge from '@/components/SFTP/Users/UserStatusBadge.vue';

export default {
  name: 'SFTPInternalUsersList',
  components: {
    UserStatusBadge,
  },
  props: {
    users: {
      type: Array,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'username',
          label: 'Username',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'groups',
          label: 'Groups',
        },
        {
          key: 'status',
          label: 'Active',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
    };
  },
};
</script>
