<template>
  <b-container class="mt-4">
    <b-row>
      <b-col cols="10">
        <h4>SFTP {{ serverType === 'external' ? 'External' : 'Internal' }} Users</h4>
      </b-col>
      <b-col cols="2">
        <b-button class="float-right" @click="() => this.$router.push('/sftp/create-user')" variant="primary">Create User</b-button>
      </b-col>
    </b-row>
    <b-row v-if="loading">
      <b-col cols="12">
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <b-row class="mt-3" >
          <b-col cols="5">
            <b-form-group label="Username"
                          label-for="input-username">
              <b-form-input id="input-username"
                            v-model="filters.username"
                            type="text"/>
            </b-form-group>
          </b-col>
          <b-col cols="5">
            <b-form-group label="Email"
                          label-for="input-email">
              <b-form-input id="input-email"
                            v-model="filters.email"
                            type="text"/>
            </b-form-group>
          </b-col>
          <b-col cols="1" class="align-self-end">
            <div class="form-group">
              <b-button variant="primary" @click="searchByFilter" style="width: 100px">Search</b-button>
            </div>
          </b-col>
          <b-col cols="1" class="align-self-end">
            <div class="form-group">
              <b-button variant="warning" @click="resetFilters" style="width: 100px" class="ml-0">Reset</b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <ExternalUsersList :users="items" v-if="serverType === 'external'"></ExternalUsersList>
            <InternalUsersList :users="items" v-if="serverType === 'internal'"></InternalUsersList>
          </b-col>
        </b-row>
        <!-- Pagination -->
        <b-row class="pt-2">
          <b-col cols="6">
            <b-button @click="goToPreviousPage"
                      class="float-right" variant="primary" :disabled="currentPage === 1" style="width: 140px">Previous Page</b-button>
          </b-col>
          <b-col cols="6">
            <b-button @click="goToNextPage" variant="primary" :disabled="!hasNext" style="width: 140px">Next Page</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import pagination from '@/mixins/lists/pagination';
import ExternalUsersList from '@/components/SFTP/Users/ExternalUsersList.vue';
import InternalUsersList from '@/components/SFTP/Users/InternalUsersList.vue';
import * as R from 'ramda';

export default {
  name: 'SFTPClientUsersConfig',
  mixins: [ pagination ],
  components: {
    InternalUsersList,
    ExternalUsersList,
  },
  props: {
    serverType: {
      type: String,
    },
  },
  watch: {
    async serverType() {
      await this.search();
    },
  },
  async beforeMount() {
    if (!this.canAccessSftpUsersAndGroups) {
      this.$router.push('/not-found');
      return;
    }

    await this.search();
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      hasNext: true,
      limit: 10,
      filters: {
        username: null,
        email: null,
      },
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      canAccessSftpUsersAndGroups: 'User/canAccessSftpUsersAndGroups',
    }),
  },
  methods: {
    async search() {
      try {
        this.loading = true;
        const payload = {
          server: this.serverType,
          filters: this.filters,
          pagination: {
            limit: this.limit,
            offset: (this.currentPage - 1) * this.limit,
          },
        };

        const { data } = await this.$store.dispatch('Sftp/User/searchUsers', payload);
        this.items = data;
        this.hasNext = this.items.length === 10;
      } catch (err) {
        console.error(err);
        this.$noty.error(`Unable to fetch users: ${R.pathOr(err, [ 'response', 'data', 'message' ], err)}`);
      } finally {
        this.loading = false;
      }
    },
    searchByFilter() {
      this.currentPage = 1;
      return this.search();
    },
    resetFilters() {
      this.filters = {
        username: null,
        email: null,
      };
      this.currentPage = 1;
      return this.search();
    },
    goToNextPage() {
      this.currentPage++;
      return this.search();
    },
    goToPreviousPage() {
      this.currentPage--;
      return this.search();
    },
  },
};
</script>
